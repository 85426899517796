<template>
  <el-dialog title="点位选择" width="1200px" :visible="selectPointShow" :close-on-click-modal="false" @close="closeDialog">
    <div class="content">
      <div class="mapBlock" id="map" ref="map"></div>
      <div class="deviceBlock">
        <el-input
          placeholder="输入查询内容"
          v-model="filterText">
        </el-input>
        <div class="checkBlock">
          <el-checkbox :indeterminate="isCheckPark" v-model="checked" @change="checkChange">全选</el-checkbox>
          <div class="line"></div>
          <el-tree
            show-checkbox
            node-key="facilityId"
            :data="list"
            :props="defaultProps"
            :default-checked-keys="facilityIds"
            default-expand-all
            :filter-node-method="filterNode"
            @check-change="treeCheckChange"
            ref="tree">
          <span slot-scope="{ node, data }">
            <span v-if="data.facilityName">{{ node.label }}</span>
            <template v-else>
              <span>{{ data.facilityTypeName }}&nbsp;
                <span class="c-default">({{data.facilityList.length}})</span>
              </span>
            </template>
          </span>
          </el-tree>
        </div>
      </div>
      <template>
        <div class="numBlock" v-if="!showTable">
            <div class="icon" @click="setBox">
            <svg class="global-icon" aria-hidden="true">
                <use xlink:href="#icon-icon_up" style="color: #303133;"></use>
            </svg>
            </div>
            <div class="info">
            <p>巡查要素</p>
            </div>
        </div>
        <div class="rightTable" v-else>
            <div class="icon" @click="setBox">
                <svg class="global-icon" aria-hidden="true">
                    <use xlink:href="#icon-icon_down" style="color: #5750EB;"></use>
                </svg>
            </div>
            <el-table class="global-table" :data="tableData">
                <el-table-column show-overflow-tooltip min-width="120" :label="`已选巡查对象（${markerCheckNum}）`">
                    <template v-slot="{row}">{{row.facilityTypeName}}({{row.count}})</template>
                </el-table-column>
                <el-table-column label="巡查要素" min-width="200">
                    <template v-slot="{ row }">
                        <p style="width: 100%; word-break: break-all; word-wrap: break-word;" v-for="(item, index) in row.element && row.element.standardList" :key="index">{{index+1}}.{{item.standardContent || '-'}}</p>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="建议巡查频次" min-width="100">
                    <template v-slot="{ row }">{{filtFrequency(row.element || [])}} </template>"
                </el-table-column>
                <el-table-column label="应急处置建议" min-width="100">
                    <template v-slot="{row}">{{row.element && row.element.emergencyDisposal}}</template>
                </el-table-column>
            </el-table>
        </div>
      </template>
    </div>
    <div class="text-center mt20">
      <el-button type="primary" @click="onSave">确认</el-button>
      <el-button type="info" @click="closeDialog">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey, iconUrl } from '../../../utils/config'
import { drawPolygon } from '../../../utils/formatData'
export default {
  name: 'PointSelect',
  props: {
    projectId: String,
    patrolUnit: Number,
    selectPointShow: Boolean,
    facilityIds: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    selectPointShow (val) {
      if (val) {
        this.init()
        this.initTable()
      }
    },
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  computed: {
  },
  data () {
    return {
      showTable: false,
      tableData: [],
      filterText: '',
      isCheckPark: false,
      checked: false,
      list: [],
      fIds: [],
      defaultProps: {
        children: 'facilityList',
        label: 'facilityName'
      },
      markerList: [],
      markerCheckNum: 0
    }
  },
  created () {
    // ....
  },
  methods: {
    init () {
      this.filterText = ''
      this.isCheckPark = false
      this.checked = false
      this.list = []
      this.fIds = []
      this.markerList = []
      this.markerCheckNum = 0
      this.getProjectLocation()
    },
    getProjectLocation () {
      this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
        projectId: this.projectId
      }).then((res) => {
        this.$nextTick(() => {
          this.loadMap(res, res.locationList)
        })
      })
    },
    loadMap (projectInfo, list) {
      const center = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      if (!this._map) {
        AMapLoader.load({
          key: mapKey,
          version: '2.0',
          plugins: []
        }).then(() => {
          const map = new AMap.Map('map', {
            center: center,
            zoom: 16
          })
          this._map = map
          this.mapInit(list)
        })
      } else {
        this._map.clearMap()
        this._map.setZoomAndCenter(16, center)
        this.mapInit(list)
      }
    },
    mapInit (list) {
      const style = {
        strokeWeight: 3,
        fillOpacity: 0,
        strokeStyle: 'dashed'
      }
      this.ploygons = drawPolygon(this._map, list || [], style, null)
      this.loadDeviceList()
    },
    setBox () {
      this.showTable = !this.showTable
    },
    loadDeviceList () {
      this.$axios.get(this.$apis.patrol.selectTypeFacilitysList, {
        projectId: this.projectId
      }).then(res => {
        this.list = res || []
        this.initMarker()
        this.list.forEach(item => {
          this.fIds.push(item.facilityId)
          if (item.facilityList) {
            item.facilityList.forEach(fItem => {
              this.fIds.push(fItem.facilityId)
            })
          }
        })
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.facilityIds)
          if (this.$refs.tree.getCheckedKeys().length === this.fIds.length && this.fIds.length !== 0) {
            this.checked = true
          } else {
            this.checked = false
            this.isCheckPark = this.facilityIds.length > 0
          }
        })
      })
    },
    initMarker () {
      this.markerList = []
      this.list.forEach(item => {
        item.facilityList.forEach(fItem => {
          const lngLat = new AMap.LngLat(Number(fItem.longitude), Number(fItem.latitude))
          const img = iconUrl + item.icon + '_1.png'
          const marker = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: img
            }),
            size: new AMap.Pixel(40, 40),
            offset: new AMap.Pixel(-20, -36),
            position: lngLat
          })
          this.markerList.push({ marker, facilityId: fItem.facilityId })
        })
      })
      if (this.facilityIds) {
        this.facilityIds.forEach(item => {
          const ind = this.markerList.findIndex(marker => marker.facilityId === item)
          if (ind >= 0) {
            this._map.add(this.markerList[ind].marker)
            this.markerCheckNum++
          }
        })
      }
      this._map.setFitView(this._map.getAllOverlays('marker'))
    },
    filterNode (value, data) {
      if (!value) return true
      if (data.facilityList) {
        return data.facilityTypeName.indexOf(value) !== -1
      } else {
        return data.facilityName.indexOf(value) !== -1
      }
    },
    checkChange (val) {
      if (val) {
        this.$refs.tree.setCheckedKeys(this.fIds)
      } else {
        this.$refs.tree.setCheckedKeys([])
      }
      this.isCheckPark = false
    },
    filtFrequency (val) {
      if (val) {
        let timeTxt = ''
        switch (val.corpFrequencyType) {
          case 1:
            timeTxt = '日'
            break
          case 2:
            timeTxt = '周'
            break
          default:
            timeTxt = '月'
            break
        }
        if (this.patrolUnit === 1) {
          if (val.corpFrequency) {
            return val.corpFrequency + '次/' + timeTxt
          } else {
            return ''
          }
        } else {
          if (val.cooperateFrequency) {
            return val.cooperateFrequency + '次/' + timeTxt
          } else {
            return ''
          }
        }
      } else {
        return ''
      }
    },
    initTable (val) {
      const checkedArr = this.$refs.tree && this.$refs.tree.getCheckedNodes(false, true)
      this.tableData = checkedArr && checkedArr.length && checkedArr.filter(item => !item.facilityId).map(item2 => {
        item2.count = checkedArr.filter(item3 => item3.facilityTypeId === item2.facilityTypeId).length - 1
        return item2
      })
    },
    treeCheckChange (data, check) {
      const checkedArr = this.$refs.tree.getCheckedNodes(false, true)
      this.tableData = checkedArr.filter(item => !item.facilityId).map(item2 => {
        item2.count = checkedArr.filter(item3 => item3.facilityTypeId === item2.facilityTypeId).length - 1
        return item2
      })
      if (data.facilityId) {
        const ind = this.markerList.findIndex(item => item.facilityId === data.facilityId)
        console.log('ind', ind)
        if (ind >= 0) {
          const lngLat = this.markerList[ind].marker.getPosition()
          if (check) {
            this._map.add(this.markerList[ind].marker)
            this.markerCheckNum++
          } else {
            this._map.remove(this.markerList[ind].marker)
            this.markerCheckNum--
          }
          this._map.setFitView(this._map.getAllOverlays('marker'))
        }
      }
      const len = this.$refs.tree.getCheckedKeys().length
      if (len === this.fIds.length) {
        this.checked = true
        this.isCheckPark = false
      } else {
        this.checked = false
        this.isCheckPark = len > 0
      }
    },
    onSave () {
      const ids = this.$refs.tree.getCheckedKeys()
      if (ids.length) {
        const checkFacilityList = []
        this.tableData.forEach((item, index) => {
          checkFacilityList.push({
            facilityTypeName: item.facilityTypeName,
            count: item.count,
            elementContent: item.element
          })
        })
        // console.log('tableData', this.tableData)
        this.$emit('setFacilityIds', ids, checkFacilityList)
        this.closeDialog()
      } else {
        this.$message.warning('请至少选择一个点位')
      }
    },
    // 关闭
    closeDialog () {
      this.$emit('update:selectPointShow', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .text-center {
    text-align: center;
  }
  .content {
    position: relative;
    width: 100%;
    height: 600px;
    .mapBlock {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .deviceBlock {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 2;
      .el-input {
        width: 300px;
        margin-bottom: 16px;
      }
      .checkBlock {
        background-color: #fff;
        .line {
          border-top: 1px solid #EBEBEB;
        }
        .el-checkbox {
          padding: 12px 20px;
        }
        .el-tree {
          padding: 20px;
          max-height: 470px;
          overflow: auto;
        }
      }
    }
    .numBlock {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 150px;
      height: 40px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      .icon {
        transform: rotate(-90deg);
        cursor: pointer;
      }
      .info {
        margin-left: 12px;
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          &.num {
            font-size: 24px;
            font-weight: 600;
            color: #333333;
            line-height: 34px;
          }
        }
      }
    }
    .rightTable {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 700px;
        height: 562px;
        overflow: auto;
        z-index: 2;
        display: flex;
        align-items: flex-start;
        .icon {
          transform: rotate(-90deg);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          background: #FFF;
          cursor: pointer;
      }
    }
  }
</style>
